<template>
  <main class="navbar-offset-tablet">
    <PageNavigator :page-title="$t('payments.breadcrumbs-list-label')">
      <template v-slot:action>
        <div>
          <span class="font-weight-medium poppins-font mr-0-5">{{ $t('payments.labels.next_payment') }}</span>
          <span>{{ paymentDate }}</span>
          <span></span>
        </div>
      </template>
    </PageNavigator>

    <div class="px-1-5 pt-7-5 full-page-height salon-flow">
      <div class="row h-100 justify-content-center">
        <div class="col-12 pb-4 text-center">
          <h4 class="">
            {{ $t('payments.labels.total') }}
            <span class="text-primary">
              {{ paymentsInfo.total }}
              CHF
            </span>
          </h4>
          <p>{{ $t('payments.form-label.subtitle') }} 0.50 CHF</p>
        </div>

        <div v-if="paymentsInfo.cards.length" class="col-12">
          <div class="row justify-content-center">
            <div class="col-12 col-md-6 pb-2">
              <div v-for="(info, key) in paymentsInfo.cards" :key="`card-${key}`" class="checkbox-container border-0">
                <div class="card mb-1 border-bottom rounded">
                  <div id="card3" class="card-header py-1">
                    <div class="row justify-content-between align-items-center">
                      <div class="col">
                        <div>
                          <label class="d-block poppins-font text-black mb-0 font-weight-medium">
                            **** **** **** {{ info.last4 }}
                          </label>
                          <p class="mb-0">
                            <span
                              :class="info.brand === 'Visa' ? 'visa-icon' : 'mastercard-icon'"
                              class="mr-0-5"
                            ></span>
                          </p>
                        </div>
                      </div>
                      <div class="col-auto checkbox centered-y">
                        <b-button v-b-toggle="`collapse-${key}`" variant="link">
                          <font-awesome-icon :icon="['fas', 'ellipsis-v']"></font-awesome-icon>
                        </b-button>
                      </div>
                    </div>
                  </div>

                  <b-collapse :id="`collapse-${key}`" accordion="templateAccordion" role="tabpanel">
                    <div class="row d-flex justify-content-between p-1-5">
                      <div class="col">
                        <b-button variant="secondary" class="btn btn-block px-4" @click="removeCard(info.card)">
                          Remove
                        </b-button>
                      </div>
                      <div class="col">
                        <b-button
                          variant="primary"
                          class="btn btn-block px-5"
                          :disabled="disabledPayment"
                          @click="createManualPaymentByCard(info.card)"
                        >
                          {{ $t('payments.buttons.pay') }}
                        </b-button>
                      </div>
                    </div>
                  </b-collapse>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="paymentsInfo.cards.length" class="col-12 text-center pb-2 text-upppercase">
          {{ $t('common.forms.label.or') }}
        </div>

        <div class="col-12">
          <div class="row justify-content-center">
            <form class="col-12 col-md-6 pb-6">
              <div class="creditCardInfoBlock">
                <div class="d-flex">
                  <label for="creditNumber" class="poppins-font text-black mb-1 payment-text font-weight-medium">{{
                    $t('common.forms.label.payment.card')
                  }}</label>
                  <p id="service-info-1" class="pr-0-5 mt-0-3 mb-0">
                    <font-awesome-icon
                      v-b-tooltip
                      class="fa-w-16 ml-0-5 tooltipInfoIcon"
                      style="font-size:16px;"
                      data-toggle="tooltip"
                      :icon="['fas', 'info-circle']"
                    ></font-awesome-icon>
                  </p>
                  <b-tooltip
                    target="service-info-1"
                    :popover-style="{ color: '#ffffff !important', background: '#fff !important' }"
                    :custom-class="'show'"
                    class="mr-5 show"
                    triggers="focus hover"
                    placement="right"
                  >
                    <div class="">
                      <h6 class="mb-2">
                        {{ $t('payment-info-tooltips.credit-card-first') }}
                      </h6>
                      <span class="mb-2">
                        {{ $t('payment-info-tooltips.credit-card-second') }}
                      </span>
                      <br />
                      <span>
                        {{ $t('payment-info-tooltips.credit-card-last') }}
                      </span>
                      <br />
                      <a
                        :href="clientAppTermsForPartnersUrl"
                        :title="$t('common.footer.terms_of_use_for_partners')"
                        target="_blank"
                      >
                        {{ $t('common.footer.terms_of_use_for_partners') }}
                      </a>
                    </div>
                  </b-tooltip>
                </div>
                <div class="input-group mb-1 d-flex flex-column">
                  <input
                    id="creditNumber"
                    v-model="paymentData.creditCardNumber"
                    v-validate="'required|numeric|min:16|max:16'"
                    name="creditCardNumber"
                    type="text"
                    class="payment-text form-control text-black input-round-borders mb-1 py-1 px-2"
                    :placeholder="$t('common.forms.placeholder.payment_creadit_card')"
                    :data-vv-as="$t('common.forms.label.payment.card_error_attribute_name')"
                  />
                  <span class="text-danger">{{ errors.first('creditCardNumber') }}</span>
                </div>
              </div>
              <div class>
                <label for="yourEmail" class="poppins-font payment-text text-black mb-1 font-weight-medium">{{
                  $t('common.forms.label.payment.email')
                }}</label>
                <div class="input-group mb-1 d-flex flex-column">
                  <input
                    id="yourEmail"
                    v-model="paymentData.email"
                    v-validate="'email|min:5'"
                    name="email"
                    type="text"
                    class="form-control payment-text text-black input-round-borders mb-1 py-1 px-2"
                    :placeholder="$t('common.forms.placeholder.payment_email')"
                    :data-vv-as="$t('common.forms.label.payment.email_error_attribute_name')"
                  />
                  <span class="text-danger">{{ errors.first('email') }}</span>
                </div>
              </div>
              <div class>
                <label for="creditName" class="poppins-font payment-text text-black mb-1 font-weight-medium">{{
                  $t('common.forms.label.payment.cardholder_name')
                }}</label>
                <div class="input-group mb-1 d-flex flex-column">
                  <input
                    id="creditName"
                    v-model="paymentData.cardHolderName"
                    v-validate="'required|alpha_spaces'"
                    name="cardHolderName"
                    type="text"
                    class="form-control payment-text text-black input-round-borders mb-1 py-1 px-2"
                    :placeholder="$t('common.forms.placeholder.payment_cardholder_name')"
                    :data-vv-as="$t('common.forms.label.payment.cardholder_name_error_attribute_name')"
                  />
                  <span class="text-danger">{{ errors.first('cardHolderName') }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6 mb-1">
                  <label for="expireDate" class="poppins-font payment-text text-black mb-1 font-weight-medium">{{
                    $t('common.forms.label.payment.expiry_date')
                  }}</label>
                  <div class="row mb-1">
                    <div class="col-6 pl-0-3 pl-md-1">
                      <select
                        v-model="paymentData.expiryDate.month"
                        v-validate="'required'"
                        name="expiryDate"
                        class="form-control w-100 px-0-5 centered-select"
                        style="font-size:14px;"
                        :data-vv-as="$t('common.forms.label.payment.expiry_datee_error_attribute_name')"
                      >
                        <option disabled selected :value="''">{{
                          $t('common.forms.placeholder.payment_mounth')
                        }}</option>
                        <option v-for="(option, key) in monthList" :key="`month-${key}`" :value="option">
                          {{ option }}
                        </option>
                      </select>
                    </div>
                    <div class="col-6">
                      <select
                        v-model="paymentData.expiryDate.year"
                        v-validate="'required'"
                        name="expireDateYear"
                        class="form-control w-100 px-0-5 centered-select"
                        style="font-size:14px;"
                        :data-vv-as="$t('common.forms.label.payment.expiry_datee_error_attribute_name')"
                      >
                        <option disabled selected :value="''">{{ $t('common.forms.placeholder.payment_year') }}</option>
                        <option v-for="(option, key) in yearList" :key="`year-${key}`" :value="option">
                          {{ option }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <span class="text-danger">{{ errors.first('expiryDate') }}</span>
                </div>
                <div class="col-12 col-md-6 pr-0-5 pr-md-1">
                  <label for="cvvCode" class="poppins-font payment-text text-black mb-1 font-weight-medium">{{
                    $t('common.forms.label.payment.cvv_code')
                  }}</label>
                  <div class="input-group mb-1 d-flex flex-column">
                    <input
                      id="cvvCode"
                      v-model="paymentData.cvcCode"
                      v-validate="'required|numeric|min:3'"
                      name="cvcCode"
                      type="text"
                      class="form-control payment-text text-black input-round-borders text-center mb-1 py-1 px-md-2 px-1"
                      :placeholder="$t('common.forms.placeholder.payment_cvc')"
                      :data-vv-as="$t('common.forms.label.payment.cvv_code')"
                      maxlength="4"
                    />
                    <span class="text-danger">{{ errors.first('cvcCode') }}</span>
                  </div>
                </div>
              </div>
              <div class="form-group mb-2-5 px-0 form-check">
                <div class="checkbox">
                  <label class="mb-0">
                    <input type="checkbox" @change="paymentData.userAgreement = !paymentData.userAgreement" />
                    <i class="input-helper"></i>
                    <p class="text-black mb-0 roboto-font">
                      {{ $t('common.forms.label.payment.agree_start') }}
                      <a
                        :href="clientAppTermsUrl"
                        target="_blank"
                        :title="$t('common.forms.label.payment.term_link')"
                        >{{ $t('common.forms.label.payment.term_link') }}</a
                      >
                      {{ $t('common.forms.label.payment.and') }}
                      <a
                        :href="clientAppPrivacyUrl"
                        target="_blank"
                        :title="$t('common.forms.label.payment.privacy_link')"
                        >{{ $t('common.forms.label.payment.privacy_link') }}</a
                      >
                      {{ $t('common.forms.label.payment.agree_end') }}
                    </p>
                  </label>
                </div>
              </div>
              <button
                type="button"
                class="btn btn-lg btn-primary w-100 d-none d-md-block"
                :disabled="disabledPayment"
                @click="handleSubmit()"
              >
                <p class="h6 font-weight-medium mb-0 text-white">
                  {{ $t('common.forms.label.payment.confirm') }}
                  <span>
                    {{ paymentsInfo.total }}
                    CHF
                  </span>
                </p>
              </button>
            </form>
          </div>
        </div>
        <div class="col-12 mb-4">
          <h3 class="mb-3">
            {{ $t('payments.reports.title') }}
          </h3>
          <PaymentReportsFilter
            :alow-download="paymentsReports.total > 0"
            @activated-filter="activatedFilter"
            @diactivated-filter="diactivatedFilter"
            @apply-filters="applyFilters"
            @download-reports-pdf="downloadReportsPDF"
          >
          </PaymentReportsFilter>
          <PaymentsReportsTable :items="paymentsReports.data"></PaymentsReportsTable>

          <CustomPagination
            :data="paymentsReports"
            :show-disabled="true"
            @pagination-change-page="changePaginationPage"
          ></CustomPagination>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import config from 'config'
import { PaymentRepository } from '@/_api/payment.repository'
import { mapActions, mapGetters } from 'vuex'
import PaymentsReportsTable from '@/components/tables/PaymentsReportsTable'
import PaymentReportsFilter from '@/components/filters/PaymentReportsFilter'
import CustomPagination from '@/components/CustomPagination'
import moment from 'moment'

/** @typedef {object} PaymentData
 *  @property {string} email
 *  @property {number|null} creditCardNumber
 *  @property {number|null} cardHolderName
 *  @property {number|null} cvvCode
 *  @property {number|null} expiryDate
 *  @property {boolean} userAgreement
 */
export default {
  name: 'SalonPaymentsForm',
  components: {
    PaymentsReportsTable,
    PaymentReportsFilter,
    CustomPagination
  },
  data() {
    return {
      paymentData: {
        email: '',
        creditCardNumber: null,
        cardHolderName: null,
        cvcCode: null,
        expiryDate: {
          month: '',
          year: ''
        },
        userAgreement: false
      },
      cachedPaymentData: null,
      paymentStatus: {
        isSending: false,
        responseStatus: ''
      },
      filtersApplied: false,
      appliedFilter: {
        value: 'all',
        label: this.$t('salon-bookings.filters.all_filters')
      },
      paymentsReportsPayload: {
        type: 'all',
        page: 1
      },
      yearList: [],
      monthList: [],
      createSuccessMessage: {
        type: this.$t('salon-services.alert.type_succes'),
        messageI18Key: 'common.alerts.actions.created'
      },
      deletedSuccessMessage: {
        type: this.$t('salon-services.alert.type_succes'),
        messageI18Key: 'common.alerts.actions.deleted'
      }
    }
  },
  computed: {
    ...mapGetters({
      accountInfo: 'user/getAccountInfo',
      paymentsReports: 'payments/getPaymentsReports',
      paymentsInfo: 'payments/getPaymentsInfo',
      siteLang: 'user/getSiteLang'
    }),
    creditCardTokenPayload() {
      const { creditCardNumber, expiryDate, cvcCode, cardHolderName } = this.paymentData
      const paymentData = {
        number: creditCardNumber,
        exp_month: expiryDate.month,
        exp_year: expiryDate.year,
        cvc: cvcCode,
        name: cardHolderName
      }
      let bodyPayload = ''

      for (const key in paymentData) {
        bodyPayload += `card[${key}]=${paymentData[key]}&`
      }

      return bodyPayload
    },
    disabledPayment() {
      return Number(this.paymentsInfo.total) < 0.5 || !this.paymentData.userAgreement
    },
    clientAppTermsUrl() {
      return `${config.clientAppUrl}/agb`
    },
    clientAppPrivacyUrl() {
      return `${config.clientAppUrl}/privacy-policy`
    },
    clientAppTermsForPartnersUrl() {
      return `${config.clientAppUrl}/agb-for-partners`
    }
  },
  watch: {
    'paymentData.expiryDate.year': {
      handler(newVal) {
        if (newVal) {
          this.monthList = this.generateMonthList()
        }
      }
    }
  },
  mounted() {
    this.yearList = this.generateYearList()
    this.monthList = this.generateMonthList()
    this.cachedPaymentData = JSON.parse(JSON.stringify(this.paymentData))

    if (this.accountInfo.salon_id) {
      this.fetchPayments(this.accountInfo.salon_id)
        .then(response => {})
        .catch(error => {})

      this.paymentsReportsPayload = {
        ...{ salon_id: this.accountInfo.salon_id },
        ...{ type: 'all' },
        ...this.paymentsReportsPayload
      }

      this.fetchPaymentsReports(this.paymentsReportsPayload)
        .then(response => {})
        .catch(error => {})
    }
  },
  methods: {
    ...mapActions({
      fetchPayments: 'payments/getPayments',
      createManualPayment: 'payments/createManualPayment',
      fetchPaymentsReports: 'payments/getPaymentsReports',
      fetchPaymentsReportsPDF: 'payments/getPaymentsReportsPDF',
      setError: 'userMessages/setError',
      setSuccess: 'userMessages/setSuccess'
    }),
    createStripePayment() {
      const stripeKey = config.stripePK

      return PaymentRepository.createStripeToken(this.creditCardTokenPayload, stripeKey)
    },
    createManualPaymentByCard(cardId) {
      if (this.disabledPayment) return

      this.createManualPayment({ currency: 'CHF', card: cardId, salon_id: this.accountInfo.salon_id })
        .then(res => {
          this.setSuccess(this.createSuccessMessage)
        })
        .catch(err => {
          console.log(err)
        })
    },
    removeCard(cardId) {
      this.createManualPayment({ currency: 'CHF', deleted_card: cardId, salon_id: this.accountInfo.salon_id })
        .then(res => {
          this.setSuccess(this.deletedSuccessMessage)
        })
        .catch(err => {
          console.log(res)
        })
    },
    handleSubmit() {
      if (!this.paymentData.userAgreement || this.disabledPayment) return

      this.$validator.validateAll().then(result => {
        if (result) {
          this.createStripePayment()
            .then(({ data: tokenData }) => {
              this.createManualPayment({ token: tokenData.id, currency: 'CHF', salon_id: this.accountInfo.salon_id })
                .then(res => {
                  this.paymentData = JSON.parse(JSON.stringify(this.cachedPaymentData))
                  this.setSuccess(this.createSuccessMessage)
                })
                .catch(err => {})
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
    },
    activatedFilter() {
      this.filtersApplied = true
    },
    diactivatedFilter() {
      this.filtersApplied = false
    },
    applyFilters(params) {
      const isChooseDateFilterValid =
        (params.day_from === undefined && params.day_to !== undefined) ||
        (params.day_from !== undefined && params.day_to === undefined)

      if (isChooseDateFilterValid) {
        this.setError({
          type: 'empty_date_fields',
          messageI18Key: 'salon-bookings.errors.empty_date_fields'
        })
        return
      }

      if (params.type) {
        let paymentsReportsPayload = {
          ...{ salon_id: this.accountInfo.salon_id },
          ...{ page: 1 },
          ...params
        }

        this.fetchPaymentsReports(paymentsReportsPayload)
          .then(response => {})
          .catch(error => {})
      }
    },
    changePaginationPage(page = 1) {
      this.paymentsReportsPayload.page = page

      this.fetchPaymentsReports(this.paymentsReportsPayload)
        .then(response => {})
        .catch(error => {})
    },
    downloadReportsPDF(params) {
      let allowDowload = params.day_from || params.day_to || params.type

      if (allowDowload) {
        let payloadParams = {
          ...{ salon_id: this.accountInfo.salon_id },
          ...params
        }

        this.fetchPaymentsReportsPDF(payloadParams)
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'revenue-reports.pdf')
            document.body.appendChild(link)
            link.click()
          })
          .catch(error => {})
      }
    },
    generateYearList() {
      const years = []
      const dateStart = moment()
      const dateEnd = moment().add(20, 'y')

      while (dateEnd.diff(dateStart, 'years') >= 0) {
        years.push(dateStart.format('YY'))
        dateStart.add(1, 'year')
      }

      return years
    },
    generateMonthList() {
      let monthList = []
      let currentYear = moment().format('YY')
      let startMonthKey = 0

      if (this.paymentData.expiryDate.year === currentYear) {
        startMonthKey = Number(moment().format('M')) - 1

        if (Number(this.paymentData.expiryDate.month) < startMonthKey) {
          this.paymentData.expiryDate.month = null
        }
      }

      for (var i = startMonthKey; i < 12; i++) {
        monthList.push(
          moment()
            .month(i)
            .format('MM')
        )
      }

      return monthList
    }
  }
}
</script>
<style lang="scss" scoped></style>
