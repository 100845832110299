<template>
  <div class="form-group row">
    <div class="col-12 col-sm-3">
      <label class="poppins-font text-black mb-0-5 font-weight-medium text-small">{{
        $t('salon-bookings.input-label.filters')
      }}</label>
      <Multiselect
        v-model="appliedFilter"
        :options="filters"
        :reduce="filter => filter.value"
        label="label"
        :searchable="false"
        :select-label="''"
        :hide-selected="false"
        :allow-empty="false"
        placeholder
        @select="selectOption"
      >
        <template slot="singleLabel" slot-scope="props">
          <span class="option__desc">
            <span class="option__title">{{ $t(props.option.label) }}</span>
          </span>
        </template>
        <template slot="caret" slot-scope="{ option, toggle }">
          <div
            class="multiselect__select d-flex align-items-center justify-content-center"
            @mousedown.prevent.stop="toggle()"
          >
            <font-awesome-icon :icon="['far', 'angle-down']"></font-awesome-icon>
          </div>
        </template>
        <template slot="option" slot-scope="props">
          <div class="option__desc">
            <span class="option__title">{{ $t(props.option.label) }}</span>
          </div>
        </template>
      </Multiselect>
    </div>
    <div v-if="showDateParams" class="col-12 col-sm-9">
      <div class="row">
        <div class="col-12 col-sm-4">
          <label class="poppins-font text-black mb-0-5 font-weight-medium text-small">{{
            $t('salon-bookings.input-label.from_date')
          }}</label>
          <vc-date-picker
            ref="calendar"
            v-model="fromDate"
            :max-date="now"
            :locale="siteLang === 'null' || !siteLang ? 'en' : siteLang"
            class="date-picker"
            :popover="{ placement: 'bottom', visibility: 'click' }"
            :attributes="calendarAttrs"
            color="orange"
            :rows="1"
            :nav-visibility="'hidden'"
            :pane-width="270"
            :disabled="true"
            is-expanded
          >
            <a slot="header-left-button">
              <font-awesome-icon :icon="['far', 'angle-left']" class="" style="font-size: 18px;"></font-awesome-icon>
            </a>
            <a slot="header-right-button">
              <font-awesome-icon :icon="['far', 'angle-right']" class="" style="font-size: 18px;"></font-awesome-icon>
            </a>
          </vc-date-picker>
        </div>
        <div class="col-12 col-sm-4">
          <label class="poppins-font text-black mb-0-5 font-weight-medium text-small">{{
            $t('salon-bookings.input-label.to_date')
          }}</label>
          <vc-date-picker
            ref="calendar"
            v-model="toDate"
            :min-date="fromDate"
            :max-date="now"
            :locale="siteLang === 'null' || !siteLang ? 'en' : siteLang"
            class="date-picker"
            :popover="{ placement: 'bottom', visibility: 'click' }"
            :attributes="calendarAttrs"
            color="orange"
            :rows="1"
            :nav-visibility="'hidden'"
            :pane-width="270"
            is-expanded
          >
            <a slot="header-left-button" slot-scope="page" @click="page.movePrevMonth()">
              <font-awesome-icon :icon="['far', 'angle-left']" class="" style="font-size: 18px;"></font-awesome-icon>
            </a>
            <a slot="header-right-button" slot-scope="page" @click="page.moveNextMonth()">
              <font-awesome-icon :icon="['far', 'angle-right']" class="" style="font-size: 18px;"></font-awesome-icon>
            </a>
          </vc-date-picker>
        </div>
        <div class="col-12 col-sm-4">
          <div class="mt-3 d-flex flex-wrap">
            <span class="text-black mb-0">{{ $t('salon-bookings.input-label.apply_filter') }}</span>
            <div v-show="!filtersApplied" class="checkbox-apply ml-1" @click="filtersApplied = true"></div>
            <font-awesome-icon
              v-show="filtersApplied"
              :icon="['fas', 'check-circle']"
              class="checkbox-apply checkbox-applied ml-1"
              @click="filtersApplied = false"
            ></font-awesome-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 text-right">
      <div
        v-if="
          (alowDownload && !dateInvalid && (emptyDate || appliedFilter.value === 'all-today')) ||
            (alowDownload && filtersApplied && !dateInvalid)
        "
        class="row"
      >
        <div class="col-12 text-right mt-2">
          <button class="btn btn-primary" @click.prevent="downloadReportsPDFEmit">
            {{ $t('payments.reports.download') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'PaymentReportsFilter',
  components: { Multiselect },
  props: {
    alowDownload: Boolean
  },
  data() {
    return {
      page: 1,
      filtersApplied: false,
      now: moment().format('YYYY-MM-DD'),
      fromDate: null,
      toDate: null,
      showDateParams: true,
      appliedFilter: {
        label: 'salon-bookings.filters.all_filters',
        value: 'all'
      },
      filters: [
        {
          label: 'salon-bookings.filters.all_filters',
          value: 'all'
        },
        {
          label: 'payments.reports.today',
          value: 'all-today'
        },
        {
          label: 'payments.reports.earnings',
          value: 'earnings'
        },
        {
          label: 'payments.reports.expenses',
          value: 'expenses'
        }
      ],
      calendarAttrs: [
        {
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      siteLang: 'user/getSiteLang'
    }),
    dateParams() {
      let params = {}

      if (this.fromDate) {
        params.day_from = moment(this.fromDate).format('YYYY-MM-DD')
      }

      if (this.toDate) {
        params.day_to = moment(this.toDate).format('YYYY-MM-DD')
      }

      return params
    },
    emptyDate() {
      return !this.fromDate && !this.toDate
    },
    dateInvalid() {
      return (this.fromDate && !this.toDate) || (!this.fromDate && this.toDate)
    }
  },
  watch: {
    fromDate(newVal, oldVal) {
      if (newVal && this.toDate) {
        let fromDate = moment(newVal)
        let toDate = moment(this.toDate)

        if (fromDate.diff(toDate, 'days') > 0) {
          this.toDate = null
        }
      }

      if (oldVal !== newVal && newVal) {
        this.diactivatedFilterEmit()
      }
    },
    toDate(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.diactivatedFilterEmit()
      }
    },
    filtersApplied(newVal) {
      if (newVal) {
        this.applyFiltersEmit(this.appliedFilter.value)
      } else {
        this.diactivatedFilterEmit()
      }
    }
  },
  methods: {
    selectOption(option) {
      const { value } = option

      if (value === 'all-today') {
        this.showDateParams = false
        this.fromDate = moment().format('YYYY-MM-DD')
        this.toDate = moment().format('YYYY-MM-DD')
      } else {
        this.showDateParams = true
        this.fromDate = null
        this.toDate = null
      }

      this.filtersApplied = true
      this.applyFiltersEmit(value)
    },
    activatedFilterEmit() {
      this.$emit('activated-filter')
    },
    diactivatedFilterEmit() {
      this.filtersApplied = false
      this.$emit('diactivated-filter')
    },
    applyFiltersEmit(type) {
      type = type === 'all-today' ? 'all' : type

      const params = {
        type: type,
        ...this.dateParams
      }

      this.$emit('apply-filters', params)
    },
    downloadReportsPDFEmit() {
      const type = this.appliedFilter.value === 'all-today' ? 'all' : this.appliedFilter.value

      const params = {
        type: type,
        ...this.dateParams
      }

      this.$emit('download-reports-pdf', params)
    }
  }
}
</script>

<style lang="scss">
.vc-py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.multiselect {
  color: #000;
}
.multiselect__tags {
  border-color: #e5e5e5;
}
.date-picker ::v-deep input {
  border-color: #e0e0e0;
}
.salon-flow input,
.salon-flow textarea {
  border: 1px solid #e5e5e5;
}
.checkbox-apply {
  border-radius: 50%;
  border: 0.5px solid #e0e0e0;
  width: 18px;
  height: 18px;
  margin-top: 3px !important;
  &:hover {
    cursor: pointer;
  }
  &.checkbox-applied {
    color: #ff6b09;
    border: none;
  }
}
</style>
